import {CSSProperties, useEffect, useState} from "react";
import {isMobile} from "../../../lib/utils/constants/PagesConstants";
import styles from "./DottedGrid.module.css"

type Props = {
    lines: number,
    color: string,
    cssMobileStyle: CSSProperties,
    cssDesktopStyle: CSSProperties
}

type DottedLineProps = {
    index: number,
    color: string,
}

type AninationDelay = {
    animationOne: string,
    animationTwo: string,
    animationThree: string
}

const DottedLine = (props: DottedLineProps) => {

    const [delayAnimation, setDelayAnimation] = useState<AninationDelay | undefined>(undefined)

    useEffect(() => {
        setDelayAnimation({
            animationOne: Math.floor(Math.random() * 1000) * props.index + 1 + "ms",
            animationTwo: Math.floor(Math.random() * 1000) * props.index + 1 + "ms",
            animationThree: Math.floor(Math.random() * 1000) * props.index + 1 + "ms"
        })
    }, [])

    return (
        <>
            {
                delayAnimation ? <div className={styles.itemContainer}>
                    <div style={{animationDelay: delayAnimation!.animationOne, backgroundColor: props.color}}></div>
                    <div style={{animationDelay: delayAnimation!.animationTwo, backgroundColor: props.color}}></div>
                    <div style={{animationDelay: delayAnimation!.animationThree, backgroundColor: props.color}}></div>
                </div> : <></>
            }
        </>
    )
}

const DottedGrid = (props: Props) => {
    const {lines, color, cssMobileStyle, cssDesktopStyle} = props
    return (
        <div className={"main-container"} style={isMobile ? cssMobileStyle : cssDesktopStyle}>
            {
                [...Array(lines)].map((item, index) => {
                    return (
                        <DottedLine index={index} color={color} key={"dotted_grid_" + index}/>
                    )
                })
            }
        </div>
    )
}

export default DottedGrid